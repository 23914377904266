import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
	Link
} from 'react-router-dom';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

function SignIn() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// check if user is logged in
		const token = localStorage.getItem('token');
		if (token) {
			window.location.href = '/'; //redirect to root URL
		}
	}, []);

	const handleLogin = (e) => {
		e.preventDefault();
		setLoading(true);
		setMessage('');
		const apiUrl = 'https://black-sharktodo-ecbzf9fgdqdyhhb0.centralindia-01.azurewebsites.net/api/login/';
		// const apiUrl = 'https://black-sharktodo-ecbzf9fgdqdyhhb0.centralindia-01.azurewebsites.net/api/login/';

		axios.post(apiUrl, {
			username,
			password
		}).then(
			response => {
				const token = response.data.token;
				localStorage.setItem('token', token);
				setIsSuccess(true);
				setMessage('Login successful!')
				window.location.reload();
				setLoading(false);
			}
		).catch(error => {
			console.log(error);
			setIsSuccess(false);
			setMessage(JSON.stringify(Object.values(error.response.data)[0][0]));
			setLoading(false);
		});
	};

	return (
		<div className='login'>
			{message !== '' &&
				<p className={`response-msg ${isSuccess ? 'success-msg' : 'error-msg'}`}>{message}</p>
			}
			<h1>SignIn</h1>
			<form onSubmit={handleLogin}>
				<label>Username</label>
				<input
					type="text"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					className='form-control'
					required
				/>
				<label className='pass-label'>
					<li>password</li>
					<li><Link to='/reset-password'>Forget-password</Link></li>
				</label>
				
				<input
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className='form-control'
				/>
				<button type='submit' className='btn btn-primary mt-2'>Submit</button>
			</form>
			<div>
				<Link to='/signup'>Create an account</Link>
			</div>
			{loading && <div className="loader-cont"><p className='loader'></p></div>}
		</div>
	)
}

export default SignIn;