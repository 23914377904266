import axios from 'axios';
import React, { useEffect, useState } from 'react';
import EmailVerifyOTP from './EmailVerifyOTP'

function SignUp() {
	const [username, setUsername] = useState('');
	const [useremail, setUseremail] = useState('')
	const [password, setPassword] = useState('');
	const [repassword, setRepassword] = useState('');
	const [message, setMessage] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [showOtpPage, setShowOtpPage] = useState(false);
	const [loading, setLoading] = useState(false);

	// check if user is logged in
	const token = localStorage.getItem('token');
	if (token) {
		window.location.href = '/'; //redirect to root URL
	}

	const sendOtp = () => {
		axios.post(
			'https://black-sharktodo-ecbzf9fgdqdyhhb0.centralindia-01.azurewebsites.net/api/sign-up/send-otp/',
			{ username: username }
		).then(Response => {
			setIsSuccess(true);
			setMessage('OTP send successfully :) to your email');
			// window.location.href = '/signup/verify-otp';
			setShowOtpPage(true);
		}).catch(error => {
			setIsSuccess(false);
			setMessage(JSON.stringify(error.response.data));
		})
	}

	const handleLogin = (e) => {
		e.preventDefault();
		setLoading(true);
		setMessage('');
		const apiUrl = 'https://black-sharktodo-ecbzf9fgdqdyhhb0.centralindia-01.azurewebsites.net/api/profiles/';
		if (password == repassword) {
			axios.post(apiUrl, {
				username: username,
				email: useremail,
				password: password
			}).then(Response => {
				setIsSuccess(true);
				setMessage('Registration Successful :) ')
				sendOtp();
				setLoading(false);
			}
			).catch(error => {
				setIsSuccess(false);
				setMessage(JSON.stringify(Object.values(error.response.data)[0][0]));
				setLoading(false);
			})
		} else {
			setIsSuccess(false);
			setMessage('passwords not match');
			setLoading(false);
		}
	};

	const signUpForm = (
		<div className='login'>
			{message !== '' &&
				<p className={`response-msg ${isSuccess ? 'success-msg' : 'error-msg'}`}>{message}</p>
			}
			<h1>SignUp</h1>
			<form onSubmit={handleLogin}>

				<label>Username</label>
				<input
					type="text"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					className='form-control'
					required
				/>

				<label>Email</label>
				<input
					type="email"
					value={useremail}
					onChange={(e) => setUseremail(e.target.value)}
					className='form-control'
					required
				/>

				<label>password</label>
				<input
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className='form-control'
					required
				/>

				<label>re-password</label>
				<input
					type="password"
					value={repassword}
					onChange={(e) => setRepassword(e.target.value)}
					className='form-control'
					required
				/>

				<button type='submit' className='btn btn-primary mt-2'>Submit</button>
			</form>
			{loading && <div className="loader-cont"><p className='loader'></p></div>}
		</div>
	)

	return (
		<>
			{showOtpPage ? <EmailVerifyOTP username={username} useremail={useremail} onSendOtp={sendOtp} /> : signUpForm}
		</>
	)
}

export default SignUp;