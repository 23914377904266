import axios from 'axios';
import React, { useEffect, useState } from 'react';

function EmailVerifyOTP(props) {
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOtpVerification = () => {
        setLoading(true);
        setMessage('');
        const apiUrl = 'https://black-sharktodo-ecbzf9fgdqdyhhb0.centralindia-01.azurewebsites.net/api/sign-up/verify-otp/';
        setMessage(`verifying the OTP! wait... ${props.username}`)
        axios.post(
            apiUrl,
            { 'username': props.username, 'otp': otp }
        ).then(Response => {
            setIsSuccess(true);
            setMessage('Email Verified successfully :) ')
            window.location.href = '/signin';
            setLoading(false);
        }).catch(error => {
            setIsSuccess(false);
            setMessage(JSON.stringify(Object.values(error.response.data)[0][0]));
            setLoading(false);
        })
    };

    const handleOtpReset = () => {
        setLoading(true);
        props.onSendOtp();
        setIsSuccess(true);
        setMessage('otp resent successfully ✔️');
        setLoading(false);
    }

    return (
        <div className='login'>
            {message !== '' &&
                <p className={`response-msg ${isSuccess ? 'success-msg' : 'error-msg'}`}>{message}</p>
            }
            <h1>OTP Verification</h1>
            <p>check your Registered email "{props.useremail}" inbox for the OTP</p>
            <div>
                <label>OTP</label>
                <input
                    type="number"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className='form-control'
                />

                <button onClick={handleOtpReset} className='btn btn-primary'>re-sent</button>
                <hr></hr>
                <button onClick={handleOtpVerification} className='btn btn-success'>Verify</button>
            </div>
            {loading && <div className="loader-cont"><p className='loader'></p></div>}
        </div>
    )
}

export default EmailVerifyOTP;